import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Loading from '../Loading'
const Home = React.lazy(() => import('../Pages/Home'))
const BookPublishing = React.lazy(() => import('../Pages/BookPublishing'))
const BookPrintingServices = React.lazy(() => import('../Pages/BookPrintingServices'))
const BookCoverDesign = React.lazy(() => import('../Pages/BookCoverDesign'))
const EBookGhostWriting = React.lazy(() => import('../Pages/EBookGhostWriting'))
const GhostWriting = React.lazy(() => import('../Pages/GhostWriting'))
const BookEditing = React.lazy(() => import('../Pages/BookEditing'))
const EBookWriting = React.lazy(() => import('../Pages/EBookWriting'))
const AudioBook = React.lazy(() => import('../Pages/AudioBook'))
const BookProofreading = React.lazy(() => import('../Pages/BookProofreading'))
const SelfPublishing = React.lazy(() => import('../Pages/SelfPublishing'))
const ContactUs = React.lazy(() => import('../Pages/ContactUs'))
const AboutUs = React.lazy(() => import('../Pages/AboutUs'))
const Portfolio = React.lazy(() => import('../Pages/Portfolio'))
const Testimonial = React.lazy(() => import('../Pages/Testimonials'))
const ThankYou = React.lazy(() => import('../Pages/ThankYou'))
const Error = React.lazy(() => import('../Pages/Error'))
const AMZPublishingCompany = React.lazy(() => import('../Pages/AMZPublishingCompany'))

const Routers = ({ settings, openLiveChat, handleShow, AMZPublishingServicesUSA }) => {

  return (
    <>
      <React.Suspense fallback={<Loading/>}>
        <Routes basename="/">
          <Route path="/" element={<Home openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/book-publishing" element={<BookPublishing openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/book-printing-services" element={<BookPrintingServices openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/book-cover-design" element={<BookCoverDesign openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/ebook-ghost-writing" element={<EBookGhostWriting openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/ghostwriting" element={<GhostWriting openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/book-editing" element={<BookEditing openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/ebook-writing" element={<EBookWriting openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/audio-book" element={<AudioBook openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/book-proofreading" element={<BookProofreading openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/self-publishing" element={<SelfPublishing openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/contact-us" element={<ContactUs openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/about-us" element={<AboutUs openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/portfolio" element={<Portfolio openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/testimonial" element={<Testimonial openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/thank-you" element={<ThankYou openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="/amz-publishing-company" element={<AMZPublishingCompany openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          {AMZPublishingServicesUSA}
          <Route path="/404" element={<Error openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
          <Route path="*" element={<Navigate to='/404' openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        </Routes>
      </React.Suspense>
    </>
  )
}

export default Routers