import React, {useState, useEffect} from 'react'
import Layouts from './layouts/Layout'
// import LoadingScreen from "./Loading";
// import { useLocation } from 'react-router-dom';

const App = () => {

  // const [isLoading, setIsLoading] = useState(true);

  // const location = useLocation();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, [location]);

  return (
    <div>
      {/* {isLoading ? (
        <LoadingScreen />
      ) : ( */}
        <Layouts />
      {/* )} */}
    </div>
  )
}

export default App