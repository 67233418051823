import React, { useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaPhoneAlt } from 'react-icons/fa';

const Header = ({ settings, openLiveChat }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <header className={`py-2 bg-transparent`} >
      <Navbar expand="lg" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/"><img src={settings && settings[0] && settings[0].DarkimgUrl ? settings[0].DarkimgUrl : ''} alt="logo" width="150" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown title="Services" show={dropdownOpen} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id="basic-nav-dropdown">
                <NavDropdown.Item href="/book-publishing">Book Publishing</NavDropdown.Item>
                <NavDropdown.Item href="/book-printing-services">Book Printing Services</NavDropdown.Item>
                <NavDropdown.Item href="/book-cover-design">Book Cover Design</NavDropdown.Item>
                <NavDropdown.Item href="/ebook-ghost-writing">EBook GhostWriting</NavDropdown.Item>
                <NavDropdown.Item href="/book-editing">Book Editing</NavDropdown.Item>
                <NavDropdown.Item href="/ebook-writing">eBook Writing</NavDropdown.Item>
                <NavDropdown.Item href="/audio-book">Audio Book</NavDropdown.Item>
                <NavDropdown.Item href="/book-proofreading">Book Proofreading</NavDropdown.Item>
                <NavDropdown.Item href="/self-publishing">Self Publishing</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/portfolio">Portfolio</Nav.Link>
              <Nav.Link href="/testimonial">Testimonials</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <Nav.Link className='serv-cta-tel position-relative' href={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`}> <span><FaPhoneAlt /> {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</span> </Nav.Link>
              <Nav.Link className='serv-cta-chat2 chats position-relative' href="#" onClick={openLiveChat}>Live Chat</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header